import {Address, Change, ChangeFilter, Changed, Created, EntityFilter, Info, InfoFilter} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.Company <br/>
*/
export interface Company extends  Changed, Created{
address?: Address;
change?: Change;
companyId?: number;
created?: Change;
info?: Info;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.CompanyFilter <br/>
*/
export interface CompanyFilter extends  EntityFilter{
companyId?: number;
companyIds?: number[];
created?: ChangeFilter;
info?: InfoFilter;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.Invitation <br/>
*/
export interface Invitation extends  Changed, Created{
change?: Change;
company?: Company;
created?: Change;
expiryDate?: string;

/**
*	@example [""]
*/
groupNames?: string[];
invitationId?: number;
invitedBy?: UserInfo;

/**
*	@example sample.invited@chem.yukawa.de
*/
invitedEmail?: string;

/**
*	@example remark: please join my company
*/
remarks?: string;
sendDate?: string;
status?: InvitationStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.InvitationFilter <br/>
*/
export interface InvitationFilter extends  EntityFilter{
companyId?: number;
internal?: boolean;
invitationId?: number;
invitedByUserId?: string;
invitedEmail?: string;
}

export enum InvitationStatus {
NEW = "NEW",
SENT = "SENT",
ACCEPTED = "ACCEPTED",
REFUSED = "REFUSED",
CANCELED = "CANCELED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.Registration <br/>
*/
export interface Registration {
address?: Address;

/**
*	@example Sample Inc.
*/
companyName?: string;

/**
*	@example secret
*/
password?: string;
user?: UserInfo;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.UserInfo <br/>
*/
export interface UserInfo extends  Changed, Created{
change?: Change;
company?: Company;
created?: Change;

/**
*	@example chem.user@example.com
*/
email?: string;

/**
*	@example Chem
*/
firstName?: string;
groups?: any[];

/**
*	@example User
*/
lastName?: string;

/**
*	@example 000002
*/
userId?: string;

/**
*	@example chem.user@example.com
*/
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.chemtrade.core.domain.profile.UserInfoFilter <br/>
*/
export interface UserInfoFilter {
companyId?: number;

/**
*	@example true
*/
external?: boolean;
}

